<template>
  <vx-card title="Credit Note Temporary">
    <vs-tabs v-model="tabs">
      <vs-tab label="Draft">
        <draft />
      </vs-tab>
      <vs-tab label="Released">
        <released />
      </vs-tab>
      <vs-tab label="Applied">
        <applied />
      </vs-tab>
      <vs-tab label="Waiting Reversal"> 
        <waitingRevers /> 
      </vs-tab>
      <vs-tab label="Reversed"> 
        <reversed /> 
      </vs-tab>
      <vs-tab label="Canceled">
        <canceled />
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import draft from "./draft/index.vue";
import released from "./released/index.vue";
import waitingApproval from "./waiting-approval/index.vue";
import approved from "./approved/index.vue";
import canceled from "./canceled/index.vue";
import applied from "./applied/index.vue";
import waitingRevers from "./waiting-reversal/index.vue";
import reversed from "./reversed/index.vue";

export default {
  components: {
    draft,
    released,
    canceled,
    "waiting-approval": waitingApproval,
    approved,
    applied,
    reversed,
    waitingRevers,
  },
  data() {
    return {
      tabs: 0,
    };
  },
};
</script>
