<template>
  <div class="flex flex-col gap-3">
    <label class="vs-input--label"><b> Clearing Date</b></label>
    <datepicker
      class="w-1/3"
      v-validate="'required'"
      :inline="false"
      name="Clearing Date"
      v-model="clearingDate"
      placeholder="Clearing Date"
      :disabled-dates="{ from: new Date() }"
    ></datepicker>
    <div class="w-full flex justify-between promp-wrapper">
      <div
        class="sm:min-w-1/2 w-1/2 border-solid border-0 border-r border-grey pr-3 mr-3 content-wrapper"
      >
        <div class="w-full">
          <span>
            <h4 class="mb-5">CN Temporary Item</h4>
          </span>
          <!-- <template v-if="creditNotes[0].invoice_element != undefined"> -->
            <vs-table :data="creditNotes">
              <template slot="thead">
                <vs-th> Code </vs-th>
                <vs-th> Customer </vs-th>
                <vs-th> Value </vs-th>
                <vs-th> Allocate </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                  <vs-td> {{ tr.invoice_code }} </vs-td>
                  <vs-td>
                    Code : {{ tr.client_code }}<br />
                    Name : {{ tr.client_name }}
                  </vs-td>
                  <vs-td>
                    {{ priceFormat(tr.paid) }}
                  </vs-td>
                  <vs-td>
                    <vue-number
                      :customClass="`w-full`"
                      v-validate="
                          `required|between:1,${parseFloat(
                                  priceFormat(tr.paid).replace(/,/g, '')
                                )
                          }`
                        "
                      v-model="invAllocate[indextr]"
                      :masked="true"
                    ></vue-number>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          <!-- </template> -->
        </div>
      </div>

      <div class="sm:min-w-1/2 w-1/2 content-wrapper">
        <div class="vx-row mb-6 w-full">
          <span>
            <h4 class="mb-5 ml-5">CN Claim & Return</h4>
          </span>
          <data-table
            :responseData="responseData"
            :propsParams="params"
            :header="header"
            @reloadDataFromChild="reloadData"
            class="w-full"
          >
            <template slot="thead">
              <th width="15%">
                <vs-checkbox v-model="checkedAll">Check All</vs-checkbox>
              </th>
            </template>
            <template slot="tbody">
              <vs-tr
                v-for="(tr, indextr) in responseData.creditNote"
                :key="indextr"
              >
                <vs-td>
                  <vs-checkbox
                    @click="addChecked(tr.ID, indextr)"
                    :checked="isChecked(tr.ID)"
                  />
                </vs-td>
                <vs-td>
                  {{ tr.Code }}
                </vs-td>
                <vs-td>
                  Code : {{ tr.CustomerCode }} <br />
                  Name : {{ tr.CustomerName }}
                </vs-td>
                <vs-td>
                  {{ priceFormat(tr.TotalValue - tr.UsedValue) }}
                </vs-td>
                <vs-td>
                  <vue-number
                    :customClass="`w-full`"
                    v-model="tr.cn_allocate_formated"
                    :masked="true"
                  ></vue-number>
                </vs-td>
              </vs-tr>
            </template>
          </data-table>
        </div>
        <div>
          <div class="vx-row mb-6 w-full" v-for="(input, k) in inputs"  :key="'coa' + k">
            <div class="w-1/4 vx-col">
              <label class="vs-input--label">COA</label>
              <multiselect
                class="selectExample"
                v-model="input.coa"
                :options="optionCoa"
                :multiple="false"
                :allow-empty="true"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder="Type to search"
                :track-by="coa.Code"
                :custom-label="coaLabel"
                @search-change="getOptionCoa"
                :disabled="disabled == true"
              >
                <template slot="singleLabel" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ dt.option.coa.Code }} - {{ dt.option.coa.Name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="dt">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ dt.option.coa.Code }} - {{ dt.option.coa.Name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </div>
  
            <div class="w-1/4 vx-col">
              <label class="vs-input--label">Cost Center</label>
              <multiselect
                class="selectExample"
                v-model="input.cost_center"
                :options="optionCostCenter"
                :multiple="false"
                :allow-empty="true"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                track-by="Name"
                label="Name"
                :disabled="disabled == true"
                @search-change="getOptionCostCenter"
              >
                <template slot="singleLabel" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title">{{ dt.option.Name }}</span>
                  </span>
                </template>
  
                <template slot="option" slot-scope="dt">
                  <div class="option__desc">
                    <span class="option__title">{{ dt.option.Name }}</span>
                  </div>
                </template>
              </multiselect>
            </div>
  
            <div class="w-1/4 vx-col">
              <label class="vs-input--label">Value</label>
              <vs-input
                class="w-full"
                type="number"
                v-model="input.additional_value"
              />
            </div>
  
            <div class="w-1/4 vx-col">
              <div class="whitespace-no-wrap mt-6 ml-6">
                <div class="flex mt-4 mr-1">
                  <vs-button
                    style="margin-right: 5px"
                    size="small"
                    @click="removeField(k)"
                    icon-pack="feather"
                    icon="icon-minus"
                    color="warning"
                  >
                  </vs-button>
                  <vs-button
                    style="margin-right: 5px"
                    size="small"
                    @click="addField(k)"
                    icon-pack="feather"
                    icon="icon-plus"
                    v-show="k == inputs.length - 1"
                  >
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-3 w-full justify-between">
      <vx-card class="bg-primary text-white">
        <div class="flex flex-col gap-3">
          <h4 class="text-white font-bold">Total Clearing Value</h4>
          <h5 class="text-white font-bold">{{ totalClearingValue }}</h5>
        </div>
      </vx-card>
      <vx-card class="bg-primary text-white">
        <div class="flex flex-col gap-3">
          <h4 class="text-white font-bold">Total CN Value</h4>
          <h5 class="text-white font-bold">{{ totalCnValue }}</h5>
        </div>
      </vx-card>
      <vx-card class="bg-primary text-white">
        <div class="flex flex-col gap-3">
          <h4 class="text-white font-bold">Total Remaining</h4>
          <h5 class="text-white font-bold">{{ totalRemaining }}</h5>
        </div>
      </vx-card>
    </div>
    <div class="flex gap-3 w-full justify-end">
      <vs-button v-on:click="addField()">Add Coa</vs-button>
      <vs-button color="danger" @click="closePrompt">Close</vs-button>
      <vs-button color="success" @click="handleClearing">Clearing</vs-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VueNumber from "@/components/vue-number/component.vue";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    VueNumber,
    Datepicker,
  },
  props: {
    creditNotes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    checkedAll(value) {
      if (value) {
        // this.checkedCreditNoteID.push(id);
        this.checkedCreditNote = [...this.responseData.creditNote];
        this.checkedCreditNoteID = this.responseData.creditNote.map(
          (invoice) => invoice.ID
        );
      } else {
        this.checkedCreditNoteID = [];
        this.checkedCreditNote = [];
      }
    },
  },
  data() {
    return {
      responseData: {},
      header: [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "credit_note_number",
        },
        {
          text: "Customer",
          value: "supplier",
        },
        {
          text: "Value",
          value: "value",
        },
        {
          text: "Allocate",
          // value: "value",
        }
      ],
      inputs: [],
      allOptionOU: [],
      optionOU: [],
      additionalValue: [],
      costCenter: [],
      coa: [],
      optionCostCenter: [],
      optionCoa: [],
      invAllocate: [],
      optionKey: 0,
      params: {
        length: 5,
        page: 1,
        search: "",
        sort: "credit_notes.id",
        order: "desc",
        status: 0,
      },
      clearing: [],
      checkedCreditNote: [],
      checkedCreditNoteID: [],
      checkedAll: false,
      //   clearingTotalValue: 0,
      //   cnTotalValue: 0,
      clearingDate: new Date(),
    };
  },
  computed: {
    totalClearingValue() {
      console.log("inv: ", this.invAllocate);
      // const total = this.creditNotes.reduce(
      //   (acc, curr) => acc + parseFloat(curr.paid),
      //   0
      // );
      if (this.invAllocate.length > 0) {
        const total = this.invAllocate.reduce(
          (acc, curr) => 
              acc + parseFloat(curr.replace(/,/g, '')),
              0
        );
        return this.priceFormat(total);
      } else {
        return this.priceFormat(0);
      }
    },
    totalCnValue() {
      console.log("cn: ", this.checkedCreditNote);
      // const total = this.checkedCreditNote.reduce(
      //   (acc, curr) => acc + (curr.TotalValue - curr.UsedValue),
      //   0
      // );
      const total = this.checkedCreditNote.reduce(
        (acc, curr) => acc + parseFloat((curr.cn_allocate_formated).replace(/,/g, '')),
        0
      );
      return this.priceFormat(total);
    },
    totalRemaining() {
      let totalClearingValue = 0
      if (this.invAllocate.length > 0) {
        totalClearingValue = this.invAllocate.reduce(
          (acc, curr) => 
              acc + parseFloat(curr.replace(/,/g, '')),
              0
        );
      }
      // const totalClearingValue = this.invAllocate.reduce(
      //   (acc, curr) => 
      //       acc + parseFloat(curr.replace(/,/g, '')),
      // );

      const totalCnValue = this.checkedCreditNote.reduce(
        (acc, curr) => acc + ((curr.cn_allocate_formated).toString().replace(/,/g, '')),
        0
      );

      const totalCoa = this.inputs.reduce(
        (acc, curr) => acc + Number(curr.additional_value),
        0
      );

      if (parseFloat(totalClearingValue) > (parseFloat(totalCnValue) + parseFloat(totalCoa))) {
        return this.priceFormat(parseFloat(totalClearingValue) - parseFloat(totalCnValue) - parseFloat(totalCoa));
      } else {
        return this.priceFormat(0);
      }
    },
  },
  methods: {
    closePrompt() {
      this.$emit("closePrompt");
      this.$emit("reloadData");
    },
    dateFormated(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    addChecked(id, idx) {
      if (!this.checkedCreditNoteID.includes(id)) {
        this.checkedCreditNoteID.push(id);
        this.checkedCreditNote.push(
          this.responseData.creditNote.find(
            (creditNote) => creditNote.ID === id
          )
        );
      } else {
        const index = this.checkedCreditNoteID.indexOf(id);
        this.checkedCreditNoteID.splice(index, 1);
        this.checkedCreditNote.splice(index, 1);
        // console.log(idx, "checkedCredit this.clearing");
        // console.log(this.clearing, "checkedCredit this.clearing");
        if (this.clearing[idx] != null) {
          this.clearing.splice(idx, 1);
        }
      }
    },
    isChecked(id) {
      return this.checkedCreditNoteID.includes(id);
    },
    addClearing(id, idx) {
      let selectCN = this.checkedCreditNote.find(
        (creditNote) => creditNote.ID === id
      )
      selectCN["invoices_clearing"] = this.clearing[idx]
      console.log(selectCN, "Added");
    },
    reloadData(params) {
      this.$vs.loading();
      params = {
        ...params,
        status: 4,
        type_in: ["Claim", "Return", "Off Invoice Principal MT MIX", "Off Invoice Principal GT MIX"],
        // customer_id:
        //   this.creditNotes.length > 0 ? this.creditNotes.CustomerID : null,
        customer_code:
          this.creditNotes.length > 0 ? this.creditNotes[0].client_code : null,
        // territory_id:
        //   this.creditNotes.length > 0 ? this.creditNotes[0].territory_id : null,
      };
      this.$http
        .get("api/v1/credit-note/data-table", { params })
        .then((response) => {
          if (response.code < 299) {
            this.responseData = response.data;
          } else {
            this.responseData = {};
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClearing() {
      if (
        this.checkedCreditNoteID.length === 0 ||
        this.checkedCreditNote.length === 0
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one credit note",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
        return;
      }

      if (this.invAllocate.length <= 0) {
        this.$vs.notify({
            title: "Error",
            text: "Please enter invoice allocated amount",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return;
      }
      
      for (let i = 0; i < this.creditNotes.length; i++) {
        this.creditNotes[i].allocate = parseFloat(this.invAllocate[i].replace(/,/g, ''))

        if (parseFloat(this.invAllocate[i].replace(/,/g, '')) <= 0) {
          this.$vs.notify({
            title: "Error",
            text: "Invoice "+ this.creditNotes[i].invoice_code +" allocate must be more than 0",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return;
        }

        if (parseFloat(this.creditNotes[i].paid) < parseFloat(this.invAllocate[i].replace(/,/g, ''))) {
          this.$vs.notify({
            title: "Error",
            text: "Invoice "+ this.creditNotes[i].invoice_code +" allocate must be less than paid amount",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return;
        }
      }

      for (let i = 0; i < this.checkedCreditNote.length; i++) {
        this.checkedCreditNote[i].cn_allocate = parseFloat(this.checkedCreditNote[i].cn_allocate_formated.toString().replace(/,/g, ''))

        if (this.checkedCreditNote[i].cn_allocate <= 0 || this.checkedCreditNote[i].cn_allocate == undefined) {
          this.$vs.notify({
            title: "Error",
            text: "CN "+ this.checkedCreditNote[i].Code +" allocate must be more than 0",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return;
        }

        if (this.checkedCreditNote[i].cn_allocate > this.checkedCreditNote[i].TotalValue - this.checkedCreditNote[i].UsedValue) {
          this.$vs.notify({
            title: "Error",
            text: "CN "+ this.checkedCreditNote[i].Code +" allocate must be less than value",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return;
        }
      }

      const cnTemp = [...this.creditNotes];
      const cn = [...this.checkedCreditNote];
      // cnTemp.sort((a, b) => a.cleared_value - b.cleared_value);
      // cn.sort(
      //   (a, b) => a.TotalValue - a.UsedValue - (b.TotalValue - b.UsedValue)
      // );
      console.log(this.inputs, "param");
      console.log("cnTemp: ", cnTemp);
      console.log("cn: ", cn);
      this.$vs.loading();
      this.$http
        .patch("api/v1/credit-note/temporary/clearing", {
          credit_note_temporary_ids: cnTemp.map((cn) => cn.id_kombinasi),
          credit_note_ids: cn.map((cn) => cn.ID),
          coa: this.inputs,
          inv_allocate: cnTemp.map((cn) => cn.allocate),
          cn_allocate: cn.map((cn) => cn.cn_allocate),
          clearing_date: this.clearingDate,
        })
        .then((response) => {
          if (response.code < 299) {
            this.$vs.notify({
              title: "Success",
              text: "Credit note has been cleared",
              color: "success",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
            this.$emit("closePrompt");
            // this.reloadData(this.params);
            this.$emit("reloadData");
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    addField() {
      this.inputs.push({
        additional_value: 0,
        cost_center: null,
        coa: null,
        ou: null,
      });
      // this.additionalCoa.push({
      //   additional_value: 0,
      //   cost_center_id: null,
      //   coa_id: null,
      //   ou_id: null,
      // });
      console.log(this.inputs);
    },
    removeField(index) {
      this.inputs.splice(index, 1);
    },
    getOptionCoa(query = "") {
      if (query.length < 3) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "code",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCoa = resp.data.records;
            // this.optionKey += 1; 
            // var _this = this;
            // resp.data.records.forEach(function (e) {
            //   _this.optionCoa.push(e.coa);
            // });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Coa option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCostCenter = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Cost Center option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    coaLabel(option) {
      return `${option.coa.Code} - ${option.coa.Name}`;
    },
    trackByCoa(option) {
      return `${option.coa.Code}-${option.coa.Name}`;
    },
  },
};
</script>

<style scoped>
.promp-wrapper {
  min-height: 60dvh;
  height: 60dvh;
  max-height: 60dvh;
  overflow: auto;
}

.content-wrapper {
  min-height: 40dvh;
  height: 60dvh;
  max-height: 60dvh;
  overflow: auto;
}
</style>
